




























import {
  Component,
  Watch,
  Vue,
} from 'vue-property-decorator';


import {
  mapState
} from 'vuex';

import {
  userModule
} from "@/store/test";
import {Test, User} from '@/models/user'
import {Geography, Province, Amphur, District} from '@/models/core'
import {City} from '@/store/city'

@Component({
  components: {},
  computed: {}
})
export default class CityComponent extends Vue {
  private response: boolean = false
  private geo: Geography[] | null = []
  private province: Province[] | null = []
  private amphur: Amphur[] | null = []
  private district: District[] | null = []

  private geoChoose: Geography | null = null
  private provinceChoose: Province | null = null
  private amphurChoose: Amphur | null = null
  private districtChoose: District | null = null


  @Watch('geoChoose')
  private async onChangeGeo(id: Geography) {
    this.province = await City.getProvince(id?.id)

  }

  @Watch('provinceChoose')
  async onChangeProvince(id: Province) {
    this.amphur = await City.getAmphur(id?.id)

  }

  @Watch('amphurChoose')
  async onChangeAmphur(id: Amphur) {
    this.district = await City.getDistrict(id?.id)

  }

  public async submitCity() {
    City.currentGeo = this.geoChoose
    City.currentProvince = this.provinceChoose
    City.currentAmphur = this.amphurChoose
    City.currentDistrict = this.districtChoose
    await City.setShowName()
    City.dialogCityState = false
  }

  private async mounted() {
    this.geo = await City.getGeo()
    this.response = true
  }

  get dialog() {
    return City.dialogCityState
  }

  async closeDialog() {
    City.dialogCityState = false
  }

}
